<template>
    <ion-page>
        <div class="register">
            <h1>Register</h1>
        </div>
    </ion-page>
</template>

<script>
import { IonPage } from '@ionic/vue';

export default {
    name: 'Register',
    components: { IonPage }
}
</script>
